document.addEventListener('DOMContentLoaded', () => {
    /**
     * Пт
     * @type {NodeListOf<Element>}
     */
    let buttons = document.querySelectorAll('[data-copy]');
    if (buttons.length > 0) {
        buttons.forEach((button) => {
            button.addEventListener('click',() => {
                let text = button.dataset.copy;

                try {
                    // Копируем текст в буфер обмена
                    navigator.clipboard.writeText(text);
                    console.log("Done!");
                } catch (err) {
                    console.error("Failed to copy: ", err);
                }
            });
        });
    }
});
